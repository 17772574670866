<template>
  <div>
    <FormCard
      :is-loading="isLoading"
      :readonly="isCurrentTab(TABS.COMPETENCIES) && isActive"
      :hide-actions="!hasActions"
      @click:cancel="onCancel"
      @click:save="onSaveForm"
    >
      <template #tabs>
        <VTabs
          :current-tab="currentTab"
          :tabs="tabs"
          @click="onClickUpdateTab"
        />
      </template>

      <template #content>
        <Details
          v-if="isCurrentTab(TABS.DETAILS)"
          ref="details"
          :data="data"
          :readonly="isActive"
          @click:save="$emit('click:save', $event)"
        />

        <OutlineSearch
          v-if="isCurrentTab(TABS.TRAININGS)"
          :id="id"
          :resource="data"
          :readonly="isActive"
          :is-adding="isAdding"
          @add="addOutline"
        />

        <Roles
          v-if="isCurrentTab(TABS.ROLES)"
          :id="id"
          :roles="data.roles"
          :endpoint="endpoint"
          :readonly="isActive"
          @add:role="$emit('refresh')"
          @remove:role="$emit('refresh')"
        />

        <Competencies
          v-if="isCurrentTab(TABS.COMPETENCIES)"
          :id="id"
          ref="competencies"
          :readonly="isActive"
          :competencies="data.competencies"
          :endpoint="endpoint"
          @add:competency="$emit('refresh')"
          @remove:competency="$emit('refresh')"
        />

        <Instructors
          v-if="isCurrentTab(TABS.INSTRUCTORS)"
          :id="id"
          :endpoint="endpoint"
          :instructors="data.instructors"
          @add:instructor="$emit('refresh')"
          @remove:instructor="$emit('refresh')"
        />
      </template>
    </FormCard>

    <Outline
      v-if="isCurrentTab(TABS.TRAININGS)"
      :id="id"
      ref="trainings"
      :readonly="isActive"
      class="mt-8"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useTabs from "@/composables/useTabs";
import useCollection from "@/composables/useCollection";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Components
import VTabs from "@/components/VTabs";
import FormCard from "@/components/FormCard";
import Details from "./forms/Details";
import OutlineSearch from "./forms/OutlineSearch";
import Outline from "./forms/Outline";
import Competencies from "@/components/templates/Competencies";
import Roles from "@/components/templates/Roles";
import Instructors from "@/components/templates/Instructors";

export default {
  components: {
    VTabs,
    Details,
    Competencies,
    FormCard,
    Roles,
    Outline,
    OutlineSearch,
    Instructors
  },
  mixins: [FormMixin],
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  emits: ["update:tab", "click:save", "refresh"],
  setup(props, context) {
    // Constants
    const TABS = {
      DETAILS: "details",
      TRAININGS: "trainings",
      ROLES: "roles",
      COMPETENCIES: "competencies",
      INSTRUCTORS: "instructors"
    };

    // Composables
    // eslint-disable-next-line
    const { buildTab, currentTab, updateTab, isCurrentTab } = useTabs(TABS.DETAILS);
    const { endpoint } = useCollection();
    const { isActive, onCancel, isCreate } = useForm(props, context);

    // Data
    const details = ref(null);
    const competencies = ref(null);
    const trainings = ref(null);
    const isAdding = ref(false);

    // Computed
    const tabs = computed(() => {
      return Object.values(TABS).map(tab => {
        return buildTab({
          name: tab,
          hideIcon: true,
          disabled: tab !== TABS.DETAILS && isCreate.value
        });
      });
    });

    const hasActions = computed(() => {
      return isCurrentTab(TABS.DETAILS) || isCurrentTab(TABS.COMPETENCIES);
    });

    // Methods
    const onSaveForm = async () => {
      switch (currentTab.value) {
        case TABS.DETAILS:
          await details.value.onSave();
          break;
        case TABS.COMPETENCIES:
          await competencies.value.onSave();
          break;
        default:
      }
    };

    const onClickUpdateTab = tab => {
      updateTab(tab);
      context.emit("update:tab", currentTab.value);
    };

    const addOutline = async id => {
      isAdding.value = true;
      await trainings.value.addOutline(id);
      isAdding.value = false;
    };

    return {
      TABS,
      isAdding,
      addOutline,
      hasActions,
      trainings,
      tabs,
      details,
      competencies,
      onSaveForm,
      onClickUpdateTab,
      // useCollection
      endpoint,
      // useForm
      isActive,
      onCancel,
      // useTabs
      currentTab,
      isCurrentTab
    };
  }
};
</script>

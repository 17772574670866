<template>
  <div>
    <div class="flex justify-between items-center">
      <div class="font-medium text-base">
        {{ $t("app.manage_trainings") }}
      </div>

      <VSearchResource
        :load="load"
        :is-adding="isAdding"
        :readonly="readonly"
        :format-options="formatLoadOptions"
        @add="$emit('add', $event)"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useTraining from "@/composables/useTraining";
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
// Components
import VSearchResource from "@/components/inputs/VSearchResource";

export default {
  components: {
    VSearchResource
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isAdding: {
      type: Boolean,
      default: false
    }
  },
  emits: ["add"],
  setup(props) {
    // Composables
    const { endpoint, searchFields } = useTraining();
    const { getText } = useTexts();
    const { loadOptions, formatOptions } = useOptions();

    // Computed
    const trainingIds = computed(() => props.resource.trainings.map(t => t.id));

    // Methods
    const load = query => {
      const filters = [
        {
          f: searchFields,
          c: "LIKE",
          v: [query]
        },
        {
          f: "status",
          c: "EQUAL",
          v: "Active"
        }
      ];

      return loadOptions(endpoint, {
        ro_f: encodeURIComponent(JSON.stringify(filters)),
        ro_r: ["texts"]
      });
    };

    const formatLoadOptions = options => {
      const formatText = option => getText(option?.texts, "name");
      const formattedOptions = formatOptions(options, { formatText });

      return formattedOptions.map(option => ({
        ...option,
        disabled: trainingIds.value.includes(option.id)
      }));
    };

    return {
      load,
      formatLoadOptions,
      // useTraining
      endpoint,
      searchFields
    };
  }
};
</script>

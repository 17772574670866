<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.add_collection')" class="section-title" />

      <LangDropdown :lang="lang" @update="onUpdateLang" />
    </div>

    <Form
      :is-loading="isLoading"
      :lang="lang.value"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
// Composables
import useCollection from "@/composables/useCollection";
import useCreate from "@/composables/useCreate";
import useLangDropdown from "@/composables/useLangDropdown";
// Components
import VTitle from "@/components/VTitle";
import LangDropdown from "@/components/LangDropdown";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form,
    LangDropdown
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // COMPUTED
    const documentTitle = `${t("app.add")} - ${t("app.collections", 2)} - ${t(
      "app.administration"
    )}`;

    // CUSTOM COMPOSABLES
    const { lang, updateLang } = useLangDropdown();
    const { endpoint, route } = useCollection();
    const { isLoading, onClickSave, onClickCancel } = useCreate(
      endpoint,
      route
    );

    return {
      documentTitle,
      // useLangDropdown
      lang,
      onUpdateLang: updateLang,
      // Create
      isLoading,
      onClickSave,
      onClickCancel
    };
  }
};
</script>

<template>
  <VCard :collapsed="isCollapsed" title-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage :color="training.colour" :name="name" class="mr-4" />

        <div>
          {{ name }}

          <div class="version">
            {{ $t("app.versions") }} {{ training?.version }}
          </div>
        </div>
      </div>
    </template>

    <template #content>
      <div v-if="details" class="mb-4">
        {{ details }}
      </div>

      <div class="grid gap-4">
        <div class="flex items-center">
          <VLine :label="$t('app.instructors', 2)" />

          <div class="flex">
            <div
              v-for="(instructor, index) in training.instructors"
              :key="instructor.id"
            >
              <VImage
                class="w-10 h-10 zoom-in image-fit"
                :class="{ '-ml-5': index !== 0 }"
                :src="getAvatarURL(instructor?.avatar?.view_path)"
                :content="`${instructor.firstname} ${instructor.lastname}`"
              />
            </div>
          </div>
        </div>

        <div class="flex">
          <VLine :label="$t('app.competencies', 2)" />

          <div class="flex">
            <div
              v-for="(competency, index) in training.competencies"
              :key="competency.id"
              class="flex"
              :class="index !== training.competencies.length - 1 ? 'comma' : ''"
            >
              {{ getText(competency?.texts, "name") }}
            </div>
          </div>
        </div>

        <div class="flex">
          <VLine :label="$t('app.tags', 2)" />

          <div
            v-for="(tag, index) in training.tags"
            :key="tag.id"
            class="flex cursor-pointer"
            :class="index !== training.tags.length - 1 ? 'comma' : ''"
            @click="onClickRedirectToTag(tag)"
          >
            {{ tag.name }}
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div class="flex">
            <VAction class="-ml-2" icon="lessons" />

            <VLine :label="$t('app.lessons', 2)" />

            {{ training.lesson_count }}
          </div>

          <div class="flex">
            <VAction icon="evaluations" />

            <VLine :label="$t('app.evaluations', 2)" />

            {{ training.evaluation_count }}
          </div>

          <div class="flex">
            <VAction icon="attestations" />

            <VLine :label="$t('app.attestations', 2)" />

            {{ training.attestation_count }}
          </div>
        </div>
      </div>
    </template>

    <template #title-actions>
      <div class="flex items-center">
        <VDrag :readonly="readonly" />

        <VDeleteAction
          :item="training"
          :disabled="readonly"
          :text-value="getText(training?.texts, 'name')"
          :delete-function="onClickDelete"
        />

        <VAction
          :icon="isCollapsed ? 'expand' : 'collapse'"
          :label="$t(`app.${isCollapsed ? 'expand' : 'collapse'}`)"
          @click="onClickToggle"
        />

        <VAction
          icon="redirect"
          :label="$t('app.redirect')"
          @click="onClickRedirect"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useRequest from "@/composables/useRequest";
import useTexts from "@/composables/useTexts";
import useTraining from "@/composables/useTraining";
// Components
import VCard from "@/components/VCard";
import VDeleteAction from "@/components/VDeleteAction";
import VDrag from "@/components/VDrag";
import VLine from "@/components/VLine";
import VAction from "@/components/tables/VAction";
import VImage from "@/components/VImage";

export default {
  components: {
    VCard,
    VDeleteAction,
    VDrag,
    VAction,
    VLine,
    VImage
  },
  props: {
    // Collection ID
    id: {
      type: [Number, String],
      required: true
    },
    training: {
      type: Object,
      required: true
    },
    collapsedAll: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["refresh"],
  setup(props, context) {
    // Misc
    const router = useRouter();

    // Data
    const isCollapsed = ref(false);

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { trimHTML, getAvatarURL } = useDisplay();
    const { route } = useTraining();

    // Computed
    const name = computed(() => getText(props.training?.texts, "name"));
    const details = computed(() => {
      return trimHTML(getText(props.training?.texts, "details"));
    });

    // Methods
    const onClickToggle = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const onClickDelete = async () => {
      await request({
        endpoint: "teaching.collections.trainings.delete",
        pathParams: {
          id: props.id,
          training_id: props.training?.id
        }
      });

      context.emit("refresh");
    };

    const onClickRedirect = () => {
      const url = router.resolve({
        name: `${route}-details`,
        params: {
          id: props.training.id
        }
      });

      window.open(url.href, "_blank");
    };

    const onClickRedirectToTag = tag => {
      router.push({
        name: "tag",
        params: { id: tag?.id }
      });
    };

    // Watch
    watch(
      () => props.collapsedAll,
      () => {
        isCollapsed.value = props.collapsedAll;
      }
    );

    return {
      name,
      details,
      isCollapsed,
      onClickToggle,
      onClickDelete,
      onClickRedirect,
      onClickRedirectToTag,
      // useDisplay
      getAvatarURL,
      // useTexts
      getText
    };
  }
};
</script>

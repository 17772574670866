<!-- eslint-disable -->
<template>
  <div>
    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert
      v-else-if="data.length === 0"
      :text="$t('app.no_outline')"
    />

    <div v-else>
      <div class="flex justify-end mb-4 -mt-4">
        <VButton
          :icon="`fal fa-chevron-double-${isCollapsedAll ? 'up' : 'down'}`"
          :label="isCollapsedAll ? $t('app.expand_all') : $t('app.collapse_all')"
          class="btn-sm btn-outline-primary bg-white"
          @click="isCollapsedAll = !isCollapsedAll"
        />
      </div>

      <VDraggable :value="data" @change="onReorder">
        <Training
          v-for="training in data"
          :key="training.id"
          :readonly="readonly"
          :id="id"
          class="mb-8"
          :collapsed-all="isCollapsedAll"
          :training="training"
          @refresh="getData"
        />
      </VDraggable>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
// Composables
import useRequest from "@/composables/useRequest";
import useCollection from "@/composables/useCollection";
// Components
import Training from "./Training";
import VDraggable from "@/components/VDraggable";
import VAlert from "@/components/VAlert";
import VButton from "@/components/VButton";

export default {
  components: {
    Training,
    VDraggable,
    VAlert,
    VButton
  },
  props: {
    // trainingId
    id: {
      type: [Number, String],
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Composables
    const { endpoint } = useCollection();
    const { request, isRequesting: isLoading } = useRequest();
    const { request: reorder } = useRequest();

    // Data
    const data = ref([]);
    const isCollapsedAll = ref(false);

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: `${endpoint}.outline`,
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? [];
    };

    const onReorder = async values => {
      const new_order = values.map((value, index) => ({
        [value.id]: index
      }));

      await reorder({
        endpoint: "teaching.collections.reorder-trainings",
        pathParams: {
          id: props.id
        },
        data: {
          new_order: JSON.stringify(new_order)
        },
        showToaster: false
      });

      await getData();
    };

    const addOutline = async id => {
      const ids = data.value.map(x => x.id);

      await request({
        endpoint: `${endpoint}.update`,
        pathParams: {
          id: props.id
        },
        data: {
          trainings: JSON.stringify([...ids, id])
        }
      });

      await getData();
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      isCollapsedAll,
      addOutline,
      onReorder,
      getData,
      isLoading,
      data
    };
  }
};
</script>
